var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'message',
    {
      'mb-2': _vm.message.lastOfSeries,
      'text-right': _vm.message.own,
      'text-left': !_vm.message.own,
    } ]},[(_vm.message.firstOfSeries)?_c('div',{class:[
      'message-data',
      {
        'text-right': _vm.message.own,
        'text-left': !_vm.message.own,
      } ]},[_c('span',{staticClass:"message-owner overline"},[_vm._v(_vm._s(_vm.message.owner))]),_vm._v(" • "),_c('span',{staticClass:"message-date overline"},[_vm._v(_vm._s(_vm.message.formattedDate))])]):_vm._e(),_c('div',{class:[
      'message-content text-left font-weight-regular pa-4',
      {
        primary: _vm.message.own,
        'blue-grey lighten-4': !_vm.message.own,
        own: _vm.message.own,
        first: _vm.message.firstOfSeries,
      } ]},[_c('span',[_c('vue-simple-markdown',{attrs:{"source":_vm.message.text}})],1)]),(_vm.showWidgets)?_c('div',{class:[
      'widget-content text-left font-weight-regular py-4 px-2',
      'blue-grey lighten-4' ]},[_c('WidgetWrapper',{attrs:{"widgets":_vm.message.payload.widgets}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }