<template>
  <v-container class="fill-height">
    <v-overlay
      v-if="showOverlay"
      :value="showOverlay"
      class="text-center"
      light
    >
      <div v-if="stage === 'loading'">
        <p class="text-h6 heading mb-6">Caricamento informazioni in corso...</p>
        <v-progress-circular indeterminate size="48" />
      </div>

      <v-card v-else-if="stage === 'error'" rounded class="px-2 py-3" light>
        <v-card-text class="text-h6 mb-6 text--black">
          Attenzione:
          {{ error }}
        </v-card-text>
        <v-icon x-large color="secondary">mdi-alert-circle-outline</v-icon>
      </v-card>
    </v-overlay>

    <VirtualAgentWrapperCX v-else-if="stage === 'loaded'" />
  </v-container>
</template>

<script>
import storeReset from '@mixins/storeReset.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import VirtualAgentWrapperCX from '@components/virtualagents/VirtualAgentWrapperCX.vue'
import { get } from '@/src/utils/functions'

export default {
  name: 'VirtualAgentSessionView',
  components: { VirtualAgentWrapperCX },
  mixins: [storeReset],
  props: ['sid'],
  page: {
    title: 'Chatta con Noi',
  },
  computed: {
    ...mapState('virtualAgentsSessions', {
      loading: get(['loading']),
      publicSession: get(['publicSession']),
      error: get(['error']),
    }),
    stage() {
      if (this.loading) return 'loading'
      else if (this.publicSession) return 'loaded'
      return 'error'
    },
    showOverlay() {
      return ['loading', 'error'].includes(this.stage)
    },
  },
  async mounted() {
    await this.checkSession({ sid: this.sid })
  },
  beforeDestroy() {
    this.resetTokenInfo()
  },
  methods: {
    ...mapActions('virtualAgentsSessions', {
      checkSession: 'checkSession',
    }),
    ...mapMutations('virtualAgentsSessions', {
      resetTokenInfo: 'RESET_TOKEN_INFO',
    }),
  },
}
</script>
