<template>
  <v-container
    class="
      virtual-agent-wrapper
      fill-height
      flex-column flex-nowrap
      justify-space-between
      align-stretch
    "
    max-height="80vh"
    fluid
  >
    <Messages ref="messages" :messages="messageList" :loading="loading" />

    <v-fade-transition>
      <ChatForm v-if="!isSessionComplete" :on-send-message="sendQuery" />
    </v-fade-transition>
  </v-container>
</template>

<script>
import Messages from '@components/virtualagents/chat/Messages.vue'
import ChatForm from '@components/virtualagents/chat/ChatForm.vue'
import { mapState } from 'vuex'
import { get } from '@utils/functions.js'
import { netRequest } from '@api/client.js'
import { EventBus, EventsType } from '@services/eventbus.js'

import { getRandomInt } from '@utils/project.js'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'VirtualAgentWrapperCX',
  components: {
    Messages,
    ChatForm,
  },
  data() {
    return {
      hasError: false,
      messageList: [],
      online: true,
      matchedIntent: null,
      parameters: null,
      page: null,
      calendarSlots: [],
      loading: true,
    }
  },
  computed: {
    ...authComputed,
    ...mapState('virtualAgentsSessions', {
      session: get(['publicSession']),
    }),
    isSessionComplete() {
      return ['End Session', 'End With Support Ask'].includes(this.page)
    },
  },
  created() {
    window.addEventListener('beforeunload', this.handleUnload, false)
    EventBus.$on(EventsType.SEND_QUERY, this.sendQuery)
  },
  async mounted() {
    console.info('Starting session')

    await this.makeRequest('opt-start', {
      ticketId: this.session.id,
      bot_name: this.session.bot_name,
      common_name: this.session.common_name,
      first_name: this.session.first_name,
      last_name: this.session.last_name,
      email: this.session.email,
      phone_number:
        this.session.mobile_phone_number || this.session.work_phone_number,
      service: this.session.servizio,
      reason: this.session.motivo,
      operator_id: this.currentUser.id,
      operator_name: this.currentUser.first_name,
      ...this.session.params,
    })
  },

  async beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleUnload)
    EventBus.$off(EventsType.SEND_QUERY)
  },

  methods: {
    async makeRequest(text, params) {
      this.loading = true

      const response = await netRequest(
        'POST',
        `../quicksupport/virtualagent`,
        {
          sid: this.session.id,
          text,
          params,
        }
      )

      const { messages, matchedIntent, parameters, page, payload } = response

      this.matchedIntent = matchedIntent
      this.parameters = parameters
      this.page = page

      await this.randomDelay()

      // adding messages to queue
      messages.forEach((text, idx) => {
        this.saveMessage(
          this.enrichMessage({ timestamp: false, own: false })({
            text,
            ...(idx === messages.length - 1 && { payload }),
          })
        )
        this.$refs.messages.scrollToBottom()
      })

      this.loading = false

      // checking session status
      if (this.isSessionComplete) {
        console.info('Closing session...')

        await this.updateSession('closed')

        this.$dialog.notify.info(
          'La sessione è terminata, puoi chiudere la finestra.',
          {
            position: 'bottom-right',
            timeout: 10000,
          }
        )
      }
    },
    async handleUnload() {
      if (!this.isSessionComplete) await this.updateSession('disconnected')
    },
    async randomDelay() {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, getRandomInt(500, 800))
      })
    },

    async updateSession(status) {
      await netRequest(
        'POST',
        `../quicksupport/virtualagent/${this.session.id}`,
        {
          status,
          output: this.parameters,
        }
      )
    },

    /**
     * Send the message to the virtual agent
     */
    sendQuery(text, value) {
      this.saveMessage(this.enrichMessage({ own: true })({ text }))

      this.makeRequest(value || text)
      this.$refs.messages.scrollToBottom()
    },

    /**
     * Standardizes props added to a message
     */
    enrichMessage({ timestamp = true, own } = {}) {
      return (message) => {
        const props = {}
        if (timestamp) props.ts = Date.now()
        else props.ts = message.ts

        props.own = own
        props.owner = own ? 'Cliente' : this.session.bot_name
        props.formattedDate = this.$moment(message.ts || props.ts).format(
          'HH:mm:ss'
        )

        return {
          ...message,
          ...props,
        }
      }
    },

    /**
     * Adapter to current storage
     */
    saveMessage(message) {
      this.messageList.push(message)
    },

    // generateSlots() {
    //   this.calendarSlots = [1, 2]
    //     .map((d) => {
    //       return [9, 12, 15, 17].map(
    //         (h) =>
    //           moment()
    //             .add(d, 'day')
    //             .hours(h)
    //             .minutes(0)
    //         // .format('DD MMMM  HH:mm')
    //       )
    //     })
    //     .flat(1)
    // },
    //
    // choiceSlot(slot) {
    //   // const slot = moment(item)
    //   this.makeRequest(`${slot.format('DD MMMM')} alle ${slot.format('HH:mm')}`)
    //   this.$refs.messages.scrollToBottom()
    // },

    // choiceOption(option) {
    //   // const slot = moment(item)
    //   this.makeRequest(option)
    //   this.$refs.messages.scrollToBottom()
    // },
  },
}
</script>

<style lang="scss">
.virtual-agent-wrapper {
  max-height: calc(100vh - 64px - 70px);
  .messages {
    flex: 100 100 auto;
    overflow: auto;
  }
  .chat-form {
    flex: 0 0 auto;
  }
}
</style>
