<template>
  <div style="min-width: 70vw">
    <v-btn
      :disabled="disabled"
      color="primary"
      depressed
      rounded
      block
      class="mb-3"
      small
      @click="onClick('asap')"
    >
      Il prima possibile
    </v-btn>

    <v-btn
      v-for="slot in calendarSlots"
      :key="slot.unix()"
      :disabled="disabled"
      color="primary"
      depressed
      rounded
      block
      class="mb-3"
      small
      @click="onClick(slot)"
    >
      <v-icon left>
        mdi-calendar-cursor
      </v-icon>
      {{ slot | moment('DD MMMM  HH:mm') }}
    </v-btn>

    <v-btn
      :disabled="disabled"
      color="primary"
      outlined
      depressed
      rounded
      block
      class="mb-3"
      small
      @click="showPicker = true"
    >
      Data personalizzata
    </v-btn>

    <v-dialog
      v-model="showPicker"
      width="500"
      transition="dialog-bottom-transition"
      hide-overlay
      :retain-focus="false"
    >
      <!--  :fullscreen="$vuetify.breakpoint.mobile"-->
      <v-card>
        <v-card-title class="text-h5 ">
          Scegli data personalizzata
        </v-card-title>

        <v-card-text>
          <v-date-picker
            v-model="selectedDate"
            show-current
            color="success"
            full-width
            show-adjacent-months
            :landscape="!$vuetify.breakpoint.mobile"
            :min="calendarStartingDate"
            :max="calendarEndDate"
            :allowed-dates="allowedDates"
            :first-day-of-week="1"
          ></v-date-picker>

          <p class="mb-1 mt-4">Fascia oraria</p>
          <v-btn-toggle
            v-model="selectedHour"
            tile
            color="primary"
            borderless
            group
            mandatory
            dense
          >
            <v-btn value="9:00" :disabled="selectedDate === null">
              <strong>9-12</strong>
              <small v-if="!$vuetify.breakpoint.mobile" class="text--secondary">
                :00
              </small>
            </v-btn>
            <v-btn value="12:00" :disabled="selectedDate === null">
              <strong>12-15</strong>
              <small v-if="!$vuetify.breakpoint.mobile" class="text--secondary">
                :00
              </small>
            </v-btn>
            <v-btn value="15:00" :disabled="selectedDate === null">
              <strong>15-17</strong>
              <small v-if="!$vuetify.breakpoint.mobile" class="text--secondary">
                :00
              </small>
            </v-btn>
            <v-btn value="17:00" :disabled="selectedDate === null">
              <strong>17-20</strong>
              <small v-if="!$vuetify.breakpoint.mobile" class="text--secondary">
                :00
              </small>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showPicker = false">
            Annulla
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="selectedDate === null"
            @click="confirmDate"
          >
            CONFERMA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus, EventsType } from '@services/eventbus.js'
import moment from 'moment'

export default {
  name: 'WidgetCalendar',
  props: {
    name: {
      type: String,
      default: null,
    },
    disable_input: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Object, Array],
      default: null,
    },
  },
  data: () => ({
    disabled: false,
    calendarSlots: [],
    showPicker: false,
    selectedDate: null,
    selectedHour: '9:00',
  }),
  computed: {
    calendarStartingDate() {
      return moment()
        .add(2, 'days')
        .startOf('day')
        .format('YYYY-MM-DD')
    },
    calendarEndDate() {
      return moment()
        .add(2, 'months')
        .endOf('month')
        .format('YYYY-MM-DD')
    },
  },
  mounted() {
    if (this.disable_input) EventBus.$emit(EventsType.SEND_QUERY_DISABLE, true)
    this.generateSlots()
  },
  methods: {
    onClick(slot) {
      this.disabled = true

      if (slot === 'asap') {
        EventBus.$emit(
          EventsType.SEND_QUERY,
          'Ricontattami al più presto',
          'Tra dieci minuti'
        )
      } else {
        const value = `${slot.format('DD MMMM')} alle ${slot.format('HH:mm')}`
        EventBus.$emit(EventsType.SEND_QUERY, value, value)
      }

      if (this.disable_input)
        EventBus.$emit(EventsType.SEND_QUERY_DISABLE, false)
    },
    generateSlots() {
      this.calendarSlots = [1, 2]
        .map((d) => {
          return [9, 12, 15, 17].map(
            (h) =>
              moment()
                .add(d, 'day')
                .hours(h)
                .minutes(0)
            // .format('DD MMMM  HH:mm')
          )
        })
        .flat(1)
    },

    choiceSlot(slot) {
      // const slot = moment(item)
      this.makeRequest(`${slot.format('DD MMMM')} alle ${slot.format('HH:mm')}`)
      this.$refs.messages.scrollToBottom()
    },

    allowedDates: (val) => moment(val).isoWeekday() < 7,

    confirmDate() {
      const slot = moment(`${this.selectedDate} ${this.selectedHour}`)
      this.showPicker = false
      return this.onClick(slot)
    },
  },
}
</script>

<style scoped></style>
