<template>
  <div>
    <h4>{{ name }}</h4>

    <v-row align="center" align-content="center">
      <v-col>
        <v-text-field
          v-model.trim="search"
          label="Inserisci indirizzo"
          clearable
          :disabled="loading"
          :loading="loading"
          @input="debouncedHandler"
        >
          <template v-slot:append-outer>
            <v-btn icon @click.native="obtainLocation">
              <v-icon>mdi-crosshairs-gps</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-alert v-if="complete && !poi.length" type="warning">
      Mi spiace ma non sono stati trovati negozi all'indirizzo: {{ search }}
    </v-alert>
    <template v-else-if="complete && poi.length">
      <Map
        width="100%"
        height="40vh"
        :markers="poi"
        :center="center"
        update-center
        popup
        zoom="14"
        class="rounded mb-6"
      >
        <template slot-scope="{ marker }">
          <strong>{{ marker.name }}</strong>
          <br />
          {{ marker.indirizzo.indirizzo }}
          <br />
          {{ marker.indirizzo.comune }} ({{ marker.indirizzo.provincia }})
          <v-divider />
          <span v-for="(value, key) in marker.contatti" :key="key">
            <br />{{ key }}: <strong>{{ value }}</strong>
          </span>
          <v-btn
            block
            small
            color="primary"
            class="mt-4"
            @click="navigate(marker.lat, marker.lng)"
          >
            <v-icon left>mdi-map-search</v-icon>
            Naviga
          </v-btn>
        </template>
      </Map>
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { netRequest } from '@api/client.js'
import Map from '@components/common/Map.vue'

export default {
  name: 'WidgetLocationPicker',
  components: { Map },
  props: {
    name: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    search: null,
    loading: false,
    complete: false,
    options: { enableHighAccuracy: true, timeout: 5000 },
    poi: [],
    center: null,
  }),
  mounted() {
    this.obtainLocation()
  },
  methods: {
    obtainLocation() {
      navigator.geolocation.getCurrentPosition(
        this.onLocationFound,
        this.onLocationError,
        this.options
      )
    },

    onLocationFound(pos) {
      const crd = pos.coords

      console.log('Your current position is:')
      console.log(`Latitude : ${crd.latitude}`)
      console.log(`Longitude: ${crd.longitude}`)
      console.log(`More or less ${crd.accuracy} meters.`)
    },

    onLocationError() {
      this.$dialog.notify.warning(
        'Non è possibile accedere alla posizione corrente, usa la ricerca manuale.',
        {
          position: 'top-right',
          timeout: 5000,
        }
      )
    },

    debouncedHandler: debounce(function(val) {
      this.searchAddress(val)
    }, 500),

    async searchAddress(query) {
      try {
        this.loading = true
        const pos = await this.reverseAddress(query)
        await this.queryStores(pos)
        this.complete = true
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    async reverseAddress(query) {
      if (!query) return

      const response = await netRequest(
        'POST',
        `../quicksupport/virtualagent/address`,
        {
          q: query,
        }
      )

      return response

      // console.log('Cerco indirizzo:', query, response)
      // return this.queryStores(response)
    },

    async queryStores(pos) {
      const { latitude, longitude } = pos
      this.center = [latitude, longitude]
      this.poi = await netRequest(
        'POST',
        `../quicksupport/virtualagent/getTimStore`,
        {
          pos: {
            latitude,
            longitude,
          },
        }
      )
    },

    navigate(lat, lng) {
      // If it's an iPhone..
      if (
        navigator.platform.indexOf('iPhone') !== -1 ||
        navigator.platform.indexOf('iPod') !== -1
      ) {
        function iOSversion() {
          if (/iP(hone|od|ad)/.test(navigator.platform)) {
            // supports iOS 2.0 and later
            const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
            return [
              parseInt(v[1], 10),
              parseInt(v[2], 10),
              parseInt(v[3] || 0, 10),
            ]
          }
        }
        const ver = iOSversion() || [0]

        let protocol = 'http://'
        if (ver[0] >= 6) {
          protocol = 'maps://'
        }
        window.location =
          protocol + 'maps.apple.com/maps?daddr=' + lat + ',' + lng + '&ll='
      } else {
        window.open('http://maps.google.com?daddr=' + lat + ',' + lng + '&ll=')
      }
    },
  },
}
</script>

<style scoped></style>
