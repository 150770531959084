<template>
  <div
    :class="[
      'message',
      {
        'mb-2': message.lastOfSeries,
        'text-right': message.own,
        'text-left': !message.own,
      },
    ]"
  >
    <div
      v-if="message.firstOfSeries"
      :class="[
        'message-data',
        {
          'text-right': message.own,
          'text-left': !message.own,
        },
      ]"
    >
      <span class="message-owner overline">{{ message.owner }}</span> &bull;
      <span class="message-date overline">{{ message.formattedDate }}</span>
    </div>
    <div
      :class="[
        'message-content text-left font-weight-regular pa-4',
        {
          primary: message.own,
          'blue-grey lighten-4': !message.own,
          own: message.own,
          first: message.firstOfSeries,
        },
      ]"
    >
      <span>
        <vue-simple-markdown :source="message.text"></vue-simple-markdown>
      </span>
    </div>

    <div
      v-if="showWidgets"
      :class="[
        'widget-content text-left font-weight-regular py-4 px-2',
        'blue-grey lighten-4',
      ]"
    >
      <WidgetWrapper :widgets="message.payload.widgets" />
    </div>
  </div>
</template>

<script>
import WidgetWrapper from '@components/virtualagents/chat/widgets/WidgetWrapper.vue'
export default {
  name: 'Message',
  components: { WidgetWrapper },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showWidgets() {
      return this.message.payload && this.message.payload.widgets
    },
  },
}
</script>

<style lang="scss">
.message {
  .message-content {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;

    padding: 10px 10px 10px 12px;
    border-radius: 20px;
    white-space: pre-wrap;
    width: fit-content;
    &.first {
      position: relative;
      z-index: 1;
      &::after {
        display: block;
        position: absolute;
        top: 0px;
        content: '';
        z-index: 0;
        width: 0;
        height: 0;
      }
    }

    &.own {
      color: white;
      &.first {
        &::after {
          right: 0;
          border-top: 18px solid var(--v-primary-base);
          border-left: 18px solid transparent;
        }
      }
    }
    &:not(.own) {
      &.first {
        &::after {
          left: 0;
          border-top: 18px solid var(--v-background-darken1);
          border-right: 18px solid transparent;
        }
      }
    }
  }
  .message-data {
    margin-top: -10px;
  }
  .widget-content {
    margin-bottom: 5px;

    padding: 10px 10px 10px 12px;
    border-radius: 20px;
    white-space: pre-wrap;
    width: fit-content;
  }
}
</style>
