<template>
  <div style="min-width: 70vw">
    <v-btn
      v-for="option in data"
      :key="option.value"
      :disabled="disabled"
      color="primary"
      depressed
      rounded
      block
      class="mb-3"
      small
      @click="onClick(option)"
    >
      <v-icon v-if="option.icon" left v-html="option.icon" />
      {{ option.label }}
    </v-btn>
  </div>
</template>

<script>
import { EventBus, EventsType } from '@services/eventbus.js'

export default {
  name: 'WidgetList',
  props: {
    name: {
      type: String,
      default: null,
    },
    disable_input: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Object, Array],
      default: null,
    },
  },
  data: () => ({
    disabled: false,
  }),
  mounted() {
    if (this.disable_input) EventBus.$emit(EventsType.SEND_QUERY_DISABLE, true)
  },
  methods: {
    onClick(item) {
      this.disabled = true
      EventBus.$emit(EventsType.SEND_QUERY, item.label, item.value)

      if (this.disable_input)
        EventBus.$emit(EventsType.SEND_QUERY_DISABLE, false)
    },
  },
}
</script>

<style scoped></style>
