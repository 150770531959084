<template>
  <div ref="messages" class="messages px-3">
    <MessageRow
      v-for="message in mappedMessages"
      :key="message.ts"
      :message="message"
    />
    <PulseLoader :loading="loading" color="#5a686e" />
  </div>
</template>

<script>
import MessageRow from '@components/virtualagents/chat/MessageRow.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { each, property } from 'lodash'
import { adjust } from '@/src/utils/functions'
export default {
  name: 'Messages',
  components: {
    MessageRow,
    PulseLoader,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Divides messages in chunk by sender, mark the first and last of each chunk
     */
    mappedMessages() {
      const series = this.chunkBy(this.messages, property('owner'))
      return series
        .map((current) => {
          const firstPass = adjust(
            0,
            this.addProps({ firstOfSeries: true }),
            current
          )

          const secondPass = adjust(
            firstPass.length - 1,
            this.addProps({ lastOfSeries: true }),
            firstPass
          )

          return secondPass
        })
        .flat()
    },
  },
  methods: {
    addProps(props) {
      return (target) => ({
        ...target,
        ...props,
      })
    },
    msToMinutes(ms) {
      return Math.floor(ms / 1000 / 60)
    },
    chunkBy(collection, predicate) {
      const chunks = []
      let prevKey = null
      let chunkValues = []
      each(collection, function(value) {
        const key = predicate.apply(null, arguments)
        if (key === prevKey) {
          chunkValues.push(value)
        } else {
          // Guard against init values
          if (chunkValues.length) {
            chunks.push(chunkValues)
          }
          prevKey = key
          chunkValues = [value]
        }
      })
      // Push hanging values
      if (chunkValues.length) {
        chunks.push(chunkValues)
      }
      return chunks
    },
    scrollToBottom() {
      // Completely render before scrolling
      this.$nextTick(() => {
        this.$refs.messages.scrollTo({
          top: this.$refs.messages.scrollHeight,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>

<style lang="scss">
.messages {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
}
</style>
