<template>
  <v-form class="chat-form" autocomplete="off" @submit.prevent="sendByEnter">
    <v-row class="row pa-2" align="center">
      <v-col class="textarea-col">
        <v-textarea
          v-model.trim="text"
          auto-grow
          class="textarea"
          filled
          placeholder="Inserisci testo"
          rounded
          rows="1"
          :disabled="disabled"
          @keydown.enter="sendByEnter"
        >
        </v-textarea>
      </v-col>
      <v-col cols="auto" class="btn-col">
        <v-btn
          :disabled="disabled || !messageCanBeSent(text)"
          class="btn"
          color="primary"
          fab
          rounded
          type="submit"
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { noop, overEvery, flow, trim, size } from 'lodash'
import { EventBus, EventsType } from '@services/eventbus.js'
export default {
  name: 'ChatForm',
  props: {
    onSendMessage: {
      type: Function,
      default: noop,
    },
  },
  data() {
    return {
      disabled: false,
      text: null,
    }
  },
  created() {
    EventBus.$on(EventsType.SEND_QUERY_DISABLE, this.disableForm)
  },
  beforeDestroy() {
    EventBus.$off(EventsType.SEND_QUERY_DISABLE)
  },
  methods: {
    disableForm(val) {
      this.disabled = val
    },

    /** Ensure the emssage is not all spaces */
    messageCanBeSent: overEvery([flow([trim, size, Boolean])]),

    /**
     * When Enter is pressed with the shift key do nothing.
     * Otherwise send the message.
     */
    sendByEnter({ shiftKey }) {
      if (!shiftKey) {
        if (this.messageCanBeSent(this.text)) {
          this.onSendMessage(this.text)
          this.text = null
        }
      }
    },
  },
}
</script>

<style lang="scss">
.chat-form {
  .row {
    .textarea-col {
      .textarea {
        .v-input__slot {
          padding-top: 4px;
          padding-bottom: 10px;
          ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background: #bdbdbd;
            border-radius: 10px;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: #999999;
          }
          ::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px;
          }
          textarea {
            max-height: 130px;
            overflow-y: auto;
          }
        }
        .v-text-field__details {
          display: none;
        }
      }
    }
    .btn-col {
      margin-top: -10px;
      .btn {
        height: 56px;
        width: 56px;
        border-radius: 50%;
      }
    }
  }
}
</style>
