<template>
  <v-row align="center" align-content="center">
    <v-col cols="2" class="text-center">
      <v-btn icon :href="href" target="_blank">
        <v-icon large>mdi-download</v-icon>
      </v-btn>
    </v-col>
    <v-col>
      <a :href="href" target="_blank">
        <strong>{{ title }}</strong>
      </a>
      <br />
      <span class="text--secondary">{{ description }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WidgetLinkDownload',
  props: {
    name: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped></style>
