<template>
  <div></div>
</template>

<script>
import { netRequest } from '@api/client.js'

export default {
  name: 'WidgetMailSender',
  props: {
    template: {
      type: String,
      default: null,
    },
    dest: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.send()
  },
  methods: {
    async send() {
      try {
        const response = await netRequest(
          'POST',
          `../quicksupport/virtualagent/mailer`,
          {
            sid: this.$route.params.sid,
            template: this.template,
            dest: this.dest,
          }
        )

        console.log('[Mailer] Send OK', response)
      } catch (err) {
        console.warn('[Mailer] Send Error', err)
      }
    },
  },
}
</script>

<style scoped></style>
