<template>
  <div>
    <div v-for="widget in widgets" :key="widget.action" class="d-block">
      <WidgetList
        v-if="widget.type === 'list'"
        :name="widget.action"
        :data="widget.data"
        :disable_input="widget.disable_input"
      />
      <WidgetCalendar
        v-else-if="widget.type === 'calendar'"
        :name="widget.action"
        :data="widget.data"
        :disable_input="widget.disable_input"
      />
      <WidgetLinkDownload
        v-else-if="widget.type === 'link_download'"
        :name="widget.action"
        :title="widget.title"
        :description="widget.description"
        :href="widget.href"
      />
      <WidgetLocationPicker
        v-else-if="widget.type === 'location_picker'"
        :name="widget.title"
        :resource="widget.resource"
      />
      <WidgetMailSender
        v-else-if="widget.type === 'mail_sender'"
        :template="widget.template"
        :dest="widget.dest"
      />

      <pre v-else>{{ widget }}</pre>
    </div>
  </div>
</template>

<script>
import WidgetList from '@components/virtualagents/chat/widgets/WidgetList.vue'
import WidgetLinkDownload from '@components/virtualagents/chat/widgets/WidgetLinkDownload.vue'
import WidgetLocationPicker from '@components/virtualagents/chat/widgets/WidgetLocationPicker.vue'
import WidgetCalendar from '@components/virtualagents/chat/widgets/WidgetCalendar.vue'
import WidgetMailSender from '@components/virtualagents/chat/widgets/WidgetMailSender.vue'
export default {
  name: 'WidgetWrapper',
  components: {
    WidgetMailSender,
    WidgetCalendar,
    WidgetLocationPicker,
    WidgetLinkDownload,
    WidgetList,
  },
  props: {
    widgets: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped></style>
