<template>
  <div
    :class="[
      'message-row d-flex',
      { 'justify-end': message.own, 'justify-start': !message.own },
    ]"
  >
    <Message :message="message" />
  </div>
</template>

<script>
import Message from '@components/virtualagents/chat/Message.vue'

export default {
  name: 'MessageRow',
  components: {
    Message,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.message-row {
  display: flex;
}
</style>
